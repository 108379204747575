import React, { FC } from 'react';
import { graphql } from 'gatsby';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import classNames from 'classnames';

import Layout from 'components/Layout';
import ProductInfo from 'components/ProductInfo';
import ProductDetailedInfo from 'components/ProductDetailedInfo';
import Article from 'components/common/Article';
import DidYouKnowLoadableWrapper from 'components/FooterPromo/DidYouKnowLoadable';
import Expertise from 'components/Expertise';
import NewsletterButtonBanner from 'components/common/NewsletterButtonBanner';
import { parseBoolean } from 'utils/parseHelpers';
import { OG_PAGE_TYPES } from 'components/common/Seo/constants';
import ArticleDate from 'components/ArticleDate';

import { IArticlePage } from './models';

import 'styles/main.scss';
import './ArticlePage.scss';

const hasRecommendedProducts = (recommendedProducts: ProductTypes.IProduct[]): boolean =>
  !!(recommendedProducts && recommendedProducts.length);

const ArticlePage: FC<IArticlePage> = ({
  pageContext: { breadCrumbs },
  data: {
    relatedArticles: { nodes: relatedArticles },
    page: {
      seoNoIndex,
      seoMetaTitle,
      seoMetaDescription,
      seoMetaKeywords,
      seoCanonicalUrl,
      seoExternalHreflangs,
      seoImage,
      ogPageType,
      fullWidthArticleBody,
      hideImage,
      link,
      createDate,
      updateDate,
      lang,
      ...article
    },
    recommendedProducts: { nodes: recommendedProducts },
    brandSettings: { productsCarouselTitleRegularText, productsCarouselTitleBoldText },
    siteSettings,
    homepageSettings,
    menu,
    footerNavigation,
    mobileAppPromoBanner,
    languageSelector,
    articleConfigs,
  },
}) => {
  const isFullWidth = parseBoolean(fullWidthArticleBody);
  const hiddenImage = parseBoolean(hideImage);
  const articleImageUrl = article?.image?.fallbackUrl;

  return (
    <Layout
      seo={{
        seoNoIndex,
        seoMetaTitle,
        seoMetaDescription,
        seoMetaKeywords,
        seoCanonicalUrl,
        seoImage: seoImage || articleImageUrl,
        seoExternalHreflangs,
        ogPageType: ogPageType || OG_PAGE_TYPES.ARTICLE,
      }}
      siteSettings={siteSettings}
      menu={menu}
      footerNavigation={footerNavigation}
      mobileAppPromoBanner={mobileAppPromoBanner}
      homepageSettings={homepageSettings}
      languageSelector={languageSelector}
      className={classNames('article-page', {
        'article-page--hidden-img': hiddenImage,
      })}
      url={link}
      additionalSchema="Article"
      schemaImageUrl={articleImageUrl}
      createDate={createDate}
      updateDate={updateDate}
    >
      <Container fluid>
        <div className="dt-top-info">
          <Row>
            <Col sm={12} md={hiddenImage ? 12 : 6}>
              <ProductInfo
                breadcrumbs={breadCrumbs}
                title={article.title}
                text={article.intro}
                type="article"
                alt={article.imageAlt}
                articleImage={article.image}
                hiddenImage={hiddenImage}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6} lg={6}>
              <ProductDetailedInfo
                tags={article.tags}
                type="article"
                hasRecommendedProducts={hasRecommendedProducts(recommendedProducts)}
                titleRecommendedProducts={siteSettings?.titleArticlePagesRecommendedProducts}
              />
            </Col>
          </Row>
        </div>
      </Container>

      <Article
        articleBody={article.articleBody}
        isFullWidth={isFullWidth}
        recommendedProducts={{
          products: recommendedProducts,
          header: {
            regularText: siteSettings?.relatedProductsRegularTitle,
            boldText: siteSettings?.relatedProductsBoldTitle,
          },
        }}
      />

      {articleConfigs ? (
        <Container>
          <Row>
            <Col lg={{ span: 8, offset: 2 }}>
              <ArticleDate
                articleConfigs={articleConfigs}
                createDate={createDate}
                updateDate={updateDate}
                lang={lang}
              />
            </Col>
          </Row>
        </Container>
      ) : null}

      {Number(siteSettings?.newsletterSignUp) ? (
        <NewsletterButtonBanner newsletterBanner={homepageSettings?.newsletterBanner} />
      ) : null}

      <Expertise
        title={{
          regularText: productsCarouselTitleRegularText,
          boldText: productsCarouselTitleBoldText,
        }}
        articles={relatedArticles}
        sectionLandmark={`${productsCarouselTitleRegularText} ${productsCarouselTitleBoldText}`}
      />
      <DidYouKnowLoadableWrapper didYouKnow={article.didYouKnow} mask />
    </Layout>
  );
};

export const query = graphql`
  query ArticlePageQuery(
    $link: String!
    $recommendedProducts: [String]
    $relatedArticlesLinks: [String]
    $lang: String
  ) {
    languageSelector(lang: { eq: $lang }) {
      ...FragmentLanguageSwitcher
    }
    siteSettings(lang: { eq: $lang }) {
      ...FragmentSiteSettings
      titleArticlePagesRecommendedProducts
    }
    menu(lang: { eq: $lang }) {
      ...FragmentHeader
    }
    footerNavigation(lang: { eq: $lang }) {
      ...FragmentFooter
    }
    mobileAppPromoBanner(lang: { eq: $lang }) {
      ...FragmentMobileAppPromoBanner
    }
    homepageSettings(lang: { eq: $lang }) {
      ...FragmentHomepageSettings
      newsletterBanner {
        buttonText
        buttonURL {
          url
        }
        titleText
        regularTitle
      }
    }
    brandSettings(lang: { eq: $lang }) {
      productsCarouselTitleRegularText
      productsCarouselTitleBoldText
    }
    articleConfigs(lang: { eq: $lang }) {
      createDateText
      updateDateText
      lang
    }
    page: umbracoArticle(link: { eq: $link }, lang: { eq: $lang }) {
      fullWidthArticleBody
      hideImage
      title
      createDate
      updateDate
      seoNoIndex
      seoMetaTitle
      seoMetaDescription
      seoMetaKeywords
      seoCanonicalUrl
      seoImage
      ogPageType
      seoExternalHreflangs {
        key
        value
      }
      link
      tags {
        name
        title
        id
        visible
      }
      intro
      heroCheckbox
      content
      didYouKnow {
        labelText
        descriptionText
        buttonText
        ariaLabel
        imageAlt
        sectionLandmark
        didYouKnowBG {
          fluid {
            srcSet
            base64
          }
          fallbackUrl
        }
        buttonURL {
          url
          icon
        }
      }
      articleBody {
        columns {
          properties {
            content
          }
        }
        gridSm
        gridMd
        gridLg
        articleSectionDescription
        structure
        videoUrl
        imageAlt
        authorName
        authorTitle
        authorImageAlt
        breakerImageAlt
        quoteText
        titleText
        image {
          fallbackUrl
          fluid {
            srcSet
            base64
          }
        }
        imageAuthor {
          fallbackUrl
          fluid {
            srcSet
            base64
          }
        }
        linksList {
          titleText
          linkURL {
            icon
            name
            url
          }
          targetCheckbox
        }
        startDescriptionText
        endDescriptionText
      }
      image {
        fallbackUrl
        fluid {
          srcSet
          base64
        }
      }
      imageAlt
    }
    relatedArticles: allUmbracoArticle(
      filter: { link: { in: $relatedArticlesLinks, nin: [$link] } }
    ) {
      nodes {
        title
        intro
        link
        label
        image {
          fallbackUrl
          fluid {
            srcSet
            base64
          }
        }
        imageAlt
      }
    }
    recommendedProducts: allUmbracoProduct(
      limit: 2
      filter: { link: { in: $recommendedProducts }, lang: { eq: $lang } }
    ) {
      nodes {
        id
        name
        link
        productColor
        recognisableFeature
        shortNameBold
        shortNameRegular
        localHeroImage {
          childImageSharp {
            fluid(maxWidth: 120) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        cardImage {
          fallbackUrl
          fluid {
            srcSet
            base64
          }
        }
        descriptionShort
        titleBrandbank
      }
    }
  }
`;

export default ArticlePage;
