import React, { FC } from 'react';

import Container from 'react-bootstrap/Container';

import HTag from 'components/common/HTag';
import CardBox from 'components/common/CardBox';
import ProductCarousel from 'components/common/ProductCarousel';
import { CarouselMasks } from 'components/common/ProductCarousel/models';

import { IExpertise } from './models';
import './Expertise.scss';

const Expertise: FC<IExpertise> = ({
  title: { regularText, boldText },
  articles,
  sectionLandmark,
}) => {
  return (
    <section className="dt-expertise" aria-label={sectionLandmark}>
      <Container fluid>
        <HTag underline size={2} regularText={regularText} boldText={boldText} />
        <ProductCarousel
          partialVissible
          type={CarouselMasks.rightMask}
          infinite={false}
          limitWidth={articles.length < 4}
          isBtnGroup={articles.length > 3}
        >
          {articles.map((article) => (
            <CardBox
              key={article.intro}
              {...article}
              labelTop={
                article.label
                  ? {
                      children: article.label,
                      type: 'primary',
                    }
                  : undefined
              }
            />
          ))}
        </ProductCarousel>
      </Container>
    </section>
  );
};

export default Expertise;
