import React, { FC, useCallback, useEffect, useState } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';
import extractUrlFromMultiPicker from 'utils/urlMultiPicker';

import Container from 'react-bootstrap/Container';
import IconCustom from 'components/common/IconCustom';

import './NewsletterButtonBanner.scss';

const NewsletterButtonBanner: FC<{
  isFixed?: boolean;
  newsletterBanner: PageContent.TNewsletterBanner;
}> = ({ isFixed, newsletterBanner }) => {
  const { regularTitle, titleText, buttonText, buttonURL } = newsletterBanner;

  const [isVisible, setVisible] = useState<boolean>(false);

  const classes = classNames('dt-newsletter-button-banner dt-container-wrapper', {
    'dt-newsletter-button-banner--fixed': isFixed,
  });

  const handleCloseBanner = useCallback(() => {
    setVisible(false);
  }, []);

  useEffect(() => {
    setVisible(true);
  }, []);

  return isVisible ? (
    <div className={classes}>
      <Container fluid>
        <span className="dt-newsletter-button-banner__text">{regularTitle}</span>
        <Link
          to={extractUrlFromMultiPicker(buttonURL)}
          className="dt-newsletter-button-banner__btn btn btn-light"
        >
          {buttonText}
        </Link>
        <span className="dt-newsletter-button-banner__text">{titleText}</span>
        {isFixed ? (
          <>
            <span className="dt-newsletter-button-banner__icon">
              <IconCustom icon="truck_icon" />
            </span>
            <button
              onClick={handleCloseBanner}
              className="dt-newsletter-button-banner__btn-close"
              type="button"
              aria-label="Close"
            >
              <IconCustom icon="close_X_icon" />
            </button>
          </>
        ) : null}
      </Container>
    </div>
  ) : null;
};

export default NewsletterButtonBanner;
