import React, { FC, useEffect } from 'react';
import loadable from '@loadable/component';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

import { gtmService } from 'services/gtmService';

import { IArticle, ArticleBlocks, IWrapperProps } from './models.d';

import './Article.scss';

const Wrapper: FC<IWrapperProps> = ({ children, isFullWidth }) => (
  <Container>
    <Row className="justify-content-md-center">
      <Col sm={12} md={12} lg={isFullWidth ? 12 : 8}>
        {children}
      </Col>
    </Row>
  </Container>
);

const Article: FC<IArticle> = ({ articleBody, recommendedProducts, isFullWidth }) => {
  useEffect(() => {
    const retailerClickHandler = (e) => {
      gtmService.emitRetailerClick({}, e.currentTarget?.dataset?.shop);
    };

    let shopAnchors: HTMLAnchorElement[];

    setTimeout(() => {
      shopAnchors = Array.from(document.querySelectorAll('[data-shop]'));

      if (shopAnchors.length) {
        shopAnchors.forEach((anchor) => {
          anchor.addEventListener('click', retailerClickHandler);
        });
      }
    }, 1000);

    return () => {
      if (shopAnchors?.length) {
        shopAnchors.forEach((anchor) => {
          anchor.removeEventListener('click', retailerClickHandler);
        });
      }
    };
  }, []);

  return (
    <div className="dt-article">
      {articleBody.map((article, idx) => {
        switch (article.structure) {
          case ArticleBlocks.FOUR_COLUMNS_ROW: {
            const FourColumnsRow = loadable(() => import('components/common/FourColumnsRow'));

            return (
              <Container key={`${ArticleBlocks.FOUR_COLUMNS_ROW}-${idx}`}>
                <Row className="justify-content-md-center">
                  <Col
                    sm={article?.gridSm || 12}
                    md={article?.gridMd || 12}
                    lg={article?.gridLg || 12}
                  >
                    <FourColumnsRow {...article} />
                  </Col>
                </Row>
              </Container>
            );
          }
          case ArticleBlocks.TEXT_BLOCK: {
            const DangerouslySetInnerHtml = loadable(() => import('../DangerouslySetInnerHtml'));

            return (
              <Wrapper
                isFullWidth={isFullWidth}
                key={`TEXT_BLOCK${article.articleSectionDescription?.substr(0, 10)}`}
              >
                <DangerouslySetInnerHtml
                  html={article.articleSectionDescription}
                  className="dt-article-main-text"
                />
              </Wrapper>
            );
          }
          case ArticleBlocks.PAGE_BREAKER_BLOCK: {
            const PageBreaker = loadable(() => import('components/common/PageBreaker'));

            return (
              <PageBreaker
                img={article.image}
                key={`PAGE_BREAKER_BLOCK${article.breakerImageAlt}-${article.image.fallbackUrl}`}
                alt={article.breakerImageAlt}
              />
            );
          }
          case ArticleBlocks.QUOTE_BLOCK: {
            const Quote = loadable(() => import('components/Quote'));

            return (
              <Wrapper
                isFullWidth={isFullWidth}
                key={`QUOTE_BLOCK${article.authorImageAlt}-${article.imageAuthor?.fallbackUrl}`}
              >
                <Quote
                  quoteText={article.quoteText}
                  quoteAuthor={article.authorName}
                  authorPosition={article.authorTitle}
                  img={article.imageAuthor}
                  alt={article.authorImageAlt}
                />
              </Wrapper>
            );
          }
          case ArticleBlocks.RECOMMENDED_PRODUCTS_BLOCK: {
            if (!recommendedProducts.products?.length) {
              return null;
            }
            const RecommendedProducts = loadable(
              () => import('components/common/Article/RecommendedProducts')
            );

            return (
              <Wrapper
                isFullWidth={isFullWidth}
                key={`RECOMMENDED_PRODUCTS_BLOCK${article.startDescriptionText}`}
              >
                <div id="recommended-products">
                  <RecommendedProducts
                    productList={recommendedProducts.products}
                    startText={article.startDescriptionText}
                    endText={article.endDescriptionText}
                    header={recommendedProducts.header}
                  />
                </div>
              </Wrapper>
            );
          }
          case ArticleBlocks.LINKS_BLOCK: {
            const LinksList = loadable(() => import('./LinksList'));

            return (
              <Wrapper isFullWidth={isFullWidth} key={`LINKS_BLOCK${article.titleText}`}>
                <LinksList titleText={article.titleText} linksList={article.linksList} />
              </Wrapper>
            );
          }
          case ArticleBlocks.VIDEO_BLOCK: {
            const VideoBlock = loadable(() => import('components/common/VideoBlock'));

            return (
              <Wrapper isFullWidth={isFullWidth} key={`VIDEO_BLOCK${article.videoUrl}`}>
                <VideoBlock
                  image={article.image}
                  imageAlt={article.imageAlt}
                  videoUrl={article.videoUrl}
                />
              </Wrapper>
            );
          }

          default:
            return null;
        }
      })}
    </div>
  );
};

export default Article;
