export enum ArticleBlocks {
  TEXT_BLOCK = 'Text Block',
  PAGE_BREAKER_BLOCK = 'Page Breaker',
  QUOTE_BLOCK = 'Quote Block',
  RECOMMENDED_PRODUCTS_BLOCK = 'Recommended Products',
  LINKS_BLOCK = 'Links Block',
  FOUR_COLUMNS_ROW = 'FourColumnsRow',
  VIDEO_BLOCK = 'Video Block',
}

export interface IArticle {
  articleBody: ArticleTypes.TArticleBody[];
  recommendedProducts: IRecommendedProducts;
  isFullWidth: boolean;
}

export interface IWrapperProps {
  isFullWidth?: boolean;
}
